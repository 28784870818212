import React, { useState } from "react"
import * as styles from "./styles.module.scss"
import { Container, Section, SectionHeading } from "components"
import { content_why_us } from "content/why-us"

const WhyChooseUs = () => {
	return (
		<>
			<Section id="why-us">
				<Container>
					<SectionHeading
						normal={content_why_us.sectionTitle.normal}
						bold={content_why_us.sectionTitle.bold}
					/>
					<div className={styles.whyUsWrapper}>
						{content_why_us.list.map((item, index) => (
							<WhyUsBlock
								icon={item.icon}
								title={item.title}
								description={item.description}
								key={index}
							/>
						))}
					</div>
				</Container>
			</Section>
		</>
	)
}

export const WhyUsBlock = ({ icon, title, description }) => {
	const [viewMore, setViewMore] = useState(false)
	return (
		<>
			<div className={styles.whyUsBlock}>
				<div className={styles.whyUsBlockInner}>
					<div className={styles.icon}>
						<img src={icon} alt="" />
					</div>
					<h3 className={styles.title}>{title}</h3>
					<div
						className={`${!viewMore && styles.text}`}
						onClick={() => {
							setViewMore(true)
						}}
						role="none"
					>
						{description}
					</div>
				</div>
			</div>
		</>
	)
}

export default WhyChooseUs
