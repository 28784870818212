import React from "react"

export default function ArrowLeft(props) {
	return (
		<svg
			width={56}
			height={56}
			viewBox="0 0 56 56"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M22.3301 43.9126C22.7734 43.9126 23.2167 43.7493 23.5667 43.3993C24.2434 42.7226 24.2434 41.6026 23.5667 40.926L10.64 27.9993L23.5667 15.0726C24.2434 14.396 24.2434 13.276 23.5667 12.5993C22.8901 11.9226 21.7701 11.9226 21.0934 12.5993L6.93005 26.7626C6.25338 27.4393 6.25338 28.5593 6.93005 29.236L21.0934 43.3993C21.4434 43.7493 21.8867 43.9126 22.3301 43.9126Z"
				fill="#26c2f3"
			/>
			<path
				d="M8.5635 29.75H47.8335C48.7902 29.75 49.5835 28.9567 49.5835 28C49.5835 27.0433 48.7902 26.25 47.8335 26.25H8.5635C7.60683 26.25 6.8135 27.0433 6.8135 28C6.8135 28.9567 7.60683 29.75 8.5635 29.75Z"
				fill="#26c2f3"
			/>
		</svg>
	)
}
