import React from "react"
import { ContactUs, Main, OurClients, OurSolutions, UseCases, WhyChooseUs } from "components"
import Helmet from "react-helmet"
import favicon from "assets/icons/favicon.png"

const Home = () => {
	return (
		<>
			<Helmet defaultTitle="JunubSMS: South Sudan's #1 Bulk SMS Service | Send &amp; Receive Bulk SMS Online">
				<link rel="icon" type="image/x-icon" href={favicon} />
			</Helmet>
			<Main>
				<WhyChooseUs />
				<OurClients />
				<OurSolutions />
				<UseCases />
				<ContactUs />
			</Main>
		</>
	)
}

export default Home
