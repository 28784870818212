import React from "react"
import * as styles from "./styles.module.scss"

const FlexContainer = ({ justify, align, direction, wrap, children, ...props }) => {
	return (
		<>
			<div
				className={`${styles.flexWrapper} ${
					(justify === "center" && styles.justifyCenter) ||
					(justify === "end" && styles.justifyEnd) ||
					(justify === "between" && styles.justifyBetween) ||
					(justify === "around" && styles.justifyAround)
				} ${
					(align === "start" && styles.alignStart) ||
					(align === "center" && styles.alignCenter) ||
					(align === "end" && styles.alignEnd)
				} ${
					(direction === "row-reverse" && styles.dirRowReverse) ||
					(direction === "column" && styles.dirColumn) ||
					(direction === "column-reverse" && styles.dirColumnReverse) ||
					(align === "end" && styles.alignEnd)
				} ${wrap && styles.wrap} `}
				{...props}
			>
				{children}
			</div>
		</>
	)
}

export default FlexContainer
