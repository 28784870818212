import React, { useState } from "react"
import { Footer, Hero } from "components"
import "bootstrap/dist/css/bootstrap.min.css"
import "scss/main.scss"
import { useLocation } from "@reach/router"
import { Button, Form, Modal, Toast, ToastContainer } from "react-bootstrap"
import { useEffect } from "react"
import axios from "axios"
import { VERIFY_API_ENDPOINT } from "constants"
import { NOTIFICATIONS } from "constants"
import { navigate } from "gatsby"

const Main = ({ children }) => {
	const location = useLocation()
	const [password, setPassword] = useState("")
	const [showModal, setShowModal] = useState(false)
	const [showToast, setShowToast] = useState(false)
	const [validated, setValidated] = useState(false)
	const [isPending, setIsPending] = useState(false)
	const [notification, setNotification] = useState("")

	const path = location.pathname
	const verificationToken = location.hash.split("=").pop()

	useEffect(() => {
		if (verificationToken) {
			setShowModal(true)
		} else {
			setShowModal(false)
		}
	}, [path])

	const handleSubmit = (e) => {
		const form = e.currentTarget
		if (form.checkValidity() === false) {
			e.preventDefault()
			e.stopPropagation()
		}
		setValidated(true)
		if (form.checkValidity() === true) {
			e.preventDefault()
			setIsPending(true)
			const payload = {
				type: "signup",
				token: verificationToken,
				password: password,
			}
			axios
				.post(VERIFY_API_ENDPOINT, payload)
				.then(() => {
					setIsPending(false)
					setShowModal(false)
					setShowToast(true)
					setNotification(NOTIFICATIONS.SUCCESS)
					navigate("http://junubsms.com/")
				})
				.catch(() => {
					setIsPending(false)
					setNotification(NOTIFICATIONS.ERROR)
				})
		}
	}

	return (
		<>
			<Hero />
			{children}
			<Footer />

			{/* Verification modal */}
			<Modal show={showModal}>
				<Modal.Header>
					<Modal.Title>Confirm your email address</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form onSubmit={handleSubmit} noValidate validated={validated}>
						<Form.Group className="mb-2">
							<Form.Control
								type="password"
								placeholder="Enter your password"
								required
								onChange={(e) => setPassword(e.target.value)}
								disabled={isPending}
							/>
						</Form.Group>
						<Button variant="primary" type="submit" disabled={isPending}>
							{isPending ? "Wait..." : "Verify"}
						</Button>
						<p className="my-2">{notification}</p>
					</Form>
				</Modal.Body>
			</Modal>

			{/* Toast */}
			<ToastContainer position="top-end" className="p-3" style={{ zIndex: 1100 }}>
				<Toast onClose={() => setShowToast(false)} show={showToast} delay={3000} autohide>
					<Toast.Header>
						<strong className="me-auto text-success">THANK YOU!</strong>
					</Toast.Header>
					<Toast.Body>Thanks for the confirmation.</Toast.Body>
				</Toast>
			</ToastContainer>
		</>
	)
}

export default Main
