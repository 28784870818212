import React from "react"

// API Endpoints
export const SIGNUP_API_ENDPOINT = "https://api.junubsms.com:4445/auth/signup"
export const VERIFY_API_ENDPOINT = "https://api.junubsms.com:4445/auth/verify"

// Notifications
export const NOTIFICATIONS = {
	ERROR: <span className="text-danger">Oops, something went wrong</span>,
	SUCCESS: <span className="text-success">Signup successful, thank you!</span>,
}
