import React from "react"
import * as styles from "./styles.module.scss"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { content_header } from "content/header"
import { Container, Popup } from "components"

const Header = () => {
	return (
		<>
			<div className={styles.header}>
				<Container>
					<div className={styles.headerInner}>
						{/* Logo */}
						<AnchorLink to={"/"} className={styles.headerLogo}>
							<img src={content_header.logo} alt="junub_sms_official_logo" />
						</AnchorLink>

						{/* Links */}
						<div className={styles.headerLinks}>
							{content_header.links.map((link, index) => (
								<AnchorLink to={link.path} className={styles.navLink} key={index}>
									{link.text}
								</AnchorLink>
							))}
							<Popup />
						</div>
					</div>
				</Container>
			</div>
		</>
	)
}

export default Header
