import React from "react"
import * as styles from "./styles.module.scss"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { Container, Section, SectionHeading } from "components"
import { content_use_cases } from "content/use-cases"
import ArrowRight from "assets/icons/ArrowRight"
import ArrowLeft from "assets/icons/ArrowLeft"

const UseCases = () => {
	// Slider settings
	const settings = {
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 5000,
		swipeToSlide: true,
		nextArrow: <ArrowRight />,
		prevArrow: <ArrowLeft />,
		responsive: [
			{
				breakpoint: 992,
				settings: {
					dots: false,
					autoplay: true,
					autoplaySpeed: 2500,
					slidesToShow: 1,
					arrows: true,
				},
			},
		],
	}
	return (
		<>
			<Section id="use-cases">
				<Container>
					<SectionHeading
						normal={content_use_cases.sectionTitle.normal}
						bold={content_use_cases.sectionTitle.bold}
					/>
					<div>
						<Slider {...settings}>
							{content_use_cases.list.map((item, index) => (
								<div key={index}>
									<UseCaseBlock
										image={item.image}
										icon={item.icon}
										title={item.title}
										description={item.description}
									/>
								</div>
							))}
						</Slider>
					</div>
				</Container>
			</Section>
		</>
	)
}

export default UseCases

export const UseCaseBlock = ({ image, icon, title, description }) => {
	return (
		<>
			<div className={styles.usecasesBlock}>
				<div className={styles.inner}>
					<div className={styles.image}>
						<img src={image} alt="" />
					</div>
					<div>
						<div className={styles.icon}>
							<img src={icon} alt="" />
						</div>
						<h3 className={styles.title}>{title}</h3>
						<p>{description}</p>
					</div>
				</div>
			</div>
		</>
	)
}
