import marketing from "assets/icons/marketing.svg"
import banking from "assets/icons/banking.svg"
import ngo from "assets/icons/ngo.svg"
import healthcare from "assets/icons/healthcare.svg"
import restaurant from "assets/icons/restaurant.svg"
import education from "assets/icons/education.svg"
import img_banking from "assets/images/img_banking.jpg"
import img_education from "assets/images/img_education.jpg"
import img_health from "assets/images/img_health.jpg"
import img_marketing from "assets/images/img_marketing.jpg"
import img_ngo from "assets/images/img_ngo.jpg"
import img_restuarant from "assets/images/img_restuarant.jpg"

export const content_use_cases = {
	sectionTitle: {
		normal: "Use",
		bold: "Cases",
	},
	list: [
		{
			image: img_banking,
			icon: banking,
			title: "Banking and Financial services",
			description: [
				"Send transnational alerts",
				"Send SMS campaign your customers love. Our platform provide powerful personalization features",
				"Control queue using SMS supported applications",
			],
		},
		{
			image: img_restuarant,
			icon: restaurant,
			title: "Restaurant and takeways",
			description: [
				"Encourage restaurant visitors to text into your SMS longcodes/shortcodes to receive special invites and offers. Grow your opt-in database.",
				"Entice customers with limited time discount vouchers for lean hours sent via SMS.",
			],
		},
		{
			image: img_marketing,
			icon: marketing,
			title: "Marketing and Ads",
			description: [
				"Easily send promotional messages using our platform. Whether it is personalizing millions of texts via multiple merge fields, sending regional language texts, sharing images and files via SMS, staggering large pushes in batches, it is all incredibly easy with JunubSMS",
			],
		},
		{
			image: img_education,
			icon: education,
			title: "Education",
			description: [
				"Use bulk SMS to send information about exam schedules, reading material, and meeting invites to students, parents and staff.",
				"Allow student to query exams results using two way SMS",
				"Get real time and quick feedback about students, teachers, courses and facilities using SMS surveys.",
			],
		},
		{
			image: img_ngo,
			icon: ngo,
			title: "NGO",
			description: [
				"No need to hand out pamphlets or send them via post. Easily create and send memorable promotional SMS campaigns",
				"Keep your donors regularly updated with reports and news of activities through a simple SMS that includes web links to relevant information",
				"Remote areas communication using SMS. Build apps on top of our gateway that can support your on site team.",
			],
		},
		{
			image: img_health,
			icon: healthcare,
			title: "Healthcare",
			description: [
				"Send automated confirmations and reminders for doctor appointments, medical tests",
				"Issue mobile visitor passes to patients' visitors and limit access to the patient rooms using SMS",
				"Allow patients to seek appointments by texting the time slot to the hospital's SMS inbox setup using short code or long code services",
				"Allow patients to request emergency assistance by texting in a pre-defined keyword to your SMS inbox.",
			],
		},
	],
}
