import React from "react"
import * as styles from "./styles.module.scss"
import { Container, Section, SectionHeading } from "components"
import { content_solutions } from "content/our-solutions"

const OurSolutions = () => {
	return (
		<>
			<Section id="solutions">
				<Container>
					<SectionHeading
						normal={content_solutions.sectionTitle.normal}
						bold={content_solutions.sectionTitle.bold}
					/>
					<div className={styles.solutionsWrapper}>
						{content_solutions.list.map((item, index) => (
							<SolutionsBlock
								icon={item.icon}
								title={item.title}
								description={item.description}
								key={index}
							/>
						))}
					</div>
				</Container>
			</Section>
		</>
	)
}

export default OurSolutions

export const SolutionsBlock = ({ icon, title, description }) => {
	return (
		<>
			<div className={styles.solutionsBlock}>
				<div className={styles.solutionsBlockInner}>
					<div className={styles.icon}>
						<img src={icon} alt="" />
					</div>
					<h3 className={styles.title}>{title}</h3>
					<p>{description}</p>
				</div>
			</div>
		</>
	)
}
