import React from "react"
import * as styles from "./styles.module.scss"
import { Container, Section, SectionHeading } from "components"
import { content_clients } from "content/our-clients"
// import Slider from "react-slick"
// import "slick-carousel/slick/slick.css"
// import "slick-carousel/slick/slick-theme.css"

const OurClients = () => {
	// const settings = {
	// 	arrows: false,
	// 	autoplay: true,
	// 	autoplaySpeed: 2500,
	// 	infinite: true,
	// 	speed: 500,
	// 	draggable: false,
	// 	slidesToShow: 5,
	// 	slidesToScroll: 1,
	// }
	return (
		<>
			<Section id="clients" className={styles.customSection}>
				<Container>
					<SectionHeading
						normal={content_clients.sectionTitle.normal}
						bold={content_clients.sectionTitle.bold}
					/>
					<div className={styles.clientsWrapper}>
						{content_clients.logos.map((item, index) => (
							<div className={styles.clientBlock} key={index}>
								<img src={item.logo} alt={item.alt} />
							</div>
						))}
					</div>
					{/* <Slider {...settings}>
						{content_clients.logos.map((item, index) => (
							<div key={index}>
								<img src={item.logo} alt={item.alt} style={{ maxWidth: 150 }} />
							</div>
						))}
					</Slider> */}
				</Container>
			</Section>
		</>
	)
}

export default OurClients
