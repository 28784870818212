import junub_sms_official_logo from "assets/logos/junub_sms_official_logo.png"

export const content_header = {
	// Logo
	logo: junub_sms_official_logo,

	// Navigation menu
	links: [
		{
			text: "Why us?",
			path: "/#why-us",
		},
		{
			text: "Clients",
			path: "/#clients",
		},
		{
			text: "Solutions",
			path: "/#solutions",
		},
		{
			text: "Use Cases",
			path: "/#use-cases",
		},
		{
			text: "Contact",
			path: "/#contact",
		},
	],
}
