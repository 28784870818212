import React from "react"
import * as styles from "./styles.module.scss"

const Section = ({ children, bg, style, ...props }) => {
	return (
		<>
			<div
				className={styles.section}
				style={{
					backgroundImage: bg && `url('${bg}')`,
					...style,
				}}
				{...props}
			>
				{children}
			</div>
		</>
	)
}

export default Section
