import React from "react"

export default function ArrowRight(props) {
	return (
		<svg
			width={56}
			height={56}
			viewBox="0 0 56 56"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M33.6699 43.9126C33.2266 43.9126 32.7833 43.7493 32.4333 43.3993C31.7566 42.7226 31.7566 41.6026 32.4333 40.926L45.36 27.9993L32.4333 15.0726C31.7566 14.396 31.7566 13.276 32.4333 12.5993C33.1099 11.9226 34.2299 11.9226 34.9066 12.5993L49.07 26.7626C49.7466 27.4393 49.7466 28.5593 49.07 29.236L34.9066 43.3993C34.5566 43.7493 34.1133 43.9126 33.6699 43.9126Z"
				fill="#26c2f3"
			/>
			<path
				d="M47.4365 29.75H8.1665C7.20984 29.75 6.4165 28.9567 6.4165 28C6.4165 27.0433 7.20984 26.25 8.1665 26.25H47.4365C48.3932 26.25 49.1865 27.0433 49.1865 28C49.1865 28.9567 48.3932 29.75 47.4365 29.75Z"
				fill="#26c2f3"
			/>
		</svg>
	)
}
