import twitter from "assets/icons/twitter.png"
import facebook from "assets/icons/facebook.png"
import linkedin from "assets/icons/linkedin.png"
import partner_logo_1 from "assets/logos/partner_logo_1.png"
import partner_logo_2 from "assets/logos/partner_logo_2.png"
import partner_logo_3 from "assets/logos/partner_logo_3.png"

export const content_footer = {
	contact: {
		title: "Get in touch",
		phone: "+211 925 415 377",
		sales: "Sales: sales@junubsms.com",
		support: "Support: info@junubsms.com",
		address: "Juba Raha Hotel, office No. 19, Central Equatoria State, Juba – South Sudan",
	},
	socials: {
		title: "Get social",
		list: [
			{
				icon: twitter,
				link: "#",
				alt: "",
			},
			{
				icon: facebook,
				link: "#",
				alt: "",
			},
			{
				icon: linkedin,
				link: "#",
				alt: "",
			},
		],
	},
	partners: {
		title: "Telecom partners",
		list: [
			{
				logo: partner_logo_1,
				alt: "",
			},
			{
				logo: partner_logo_2,
				alt: "",
			},
			{
				logo: partner_logo_3,
				alt: "our partner: digitel",
			},
		],
	},
}
