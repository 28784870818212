import auto_response from "assets/icons/auto_response.svg"
import send_message from "assets/icons/send_message.svg"
import msg_chat from "assets/icons/msg_chat.svg"
import sms_polls from "assets/icons/sms_polls.svg"
import receive_sms from "assets/icons/receive_sms.svg"
import delivery_report from "assets/icons/delivery_report.svg"
import ussd from "assets/icons/ussd.svg"
import air_time from "assets/icons/air_time.svg"

export const content_why_us = {
	sectionTitle: {
		normal: "Why",
		bold: "Choose us",
	},
	list: [
		{
			icon: send_message,
			title: "Send message",
			description: "Easily Send SMS with different methods (web, http, smpp)",
		},
		{
			icon: receive_sms,
			title: "Receive SMS",
			description:
				"Easily receive SMS in South Sudan using a combination of catchy keywords, short codes and long codes provided by JunubSMS",
		},
		{
			icon: auto_response,
			title: "Auto response",
			description:
				"Easily compose an automated SMS response that is sent to every customer who texts to your short code",
		},
		{
			icon: msg_chat,
			title: "Two-way SMS",
			description:
				"You can request short codes, long codes or even use one of our shared short codes to receive and send messages to your customer. Create unlimited keywords on these codes too.",
		},
		{
			icon: sms_polls,
			title: "SMS Polls",
			description:
				"Conduct polls, interact with customers, and build your database by receiving SMS",
		},
		{
			icon: air_time,
			title: "Airtime",
			description:
				"Send airtime easily to your customers in South Sudan using our simple REST API feature.",
		},
		{
			icon: delivery_report,
			title: "Delivery Report",
			description:
				"Know at any given time the status of outgoing SMS sent using any of our various methods",
		},
		{
			icon: ussd,
			title: "USSD",
			description: "Easily build interactive aplications on top of USSD framework",
		},
	],
}
