import React from "react"
import * as styles from "./styles.module.scss"

const HeroHeading = ({ children }) => {
	return (
		<>
			<h1 className={styles.heroHeading}>{children}</h1>
		</>
	)
}

export default HeroHeading
