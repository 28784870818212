import React from "react"
import * as styles from "./styles.module.scss"
import { Container } from "components"
import { content_footer } from "content/footer"

const Footer = () => {
	return (
		<>
			<footer className={styles.footer}>
				<Container>
					<div className={styles.footerInner}>
						<Col>
							<ColTitle>{content_footer.contact.title}</ColTitle>
							<div>
								<p>{content_footer.contact.phone}</p>
								<p>{content_footer.contact.sales}</p>
								<p>{content_footer.contact.support}</p>
								<p>{content_footer.contact.address}</p>
							</div>
						</Col>

						<Col>
							<ColTitle>{content_footer.socials.title}</ColTitle>
							{content_footer.socials.list.map((item, index) => (
								<a
									href={item.link}
									target="_blank"
									rel="noreferrer"
									className={styles.socialLink}
									key={index}
								>
									<img src={item.icon} alt={item.alt} />
								</a>
							))}
						</Col>

						<Col>
							<ColTitle>{content_footer.partners.title}</ColTitle>
							<div className={styles.partners}>
								{content_footer.partners.list.map((item, index) => (
									<img src={item.logo} alt={item.alt} key={index} />
								))}
							</div>
						</Col>
					</div>
				</Container>
			</footer>
			<div className={styles.copyright}>Junub SMS &copy; 2022</div>
		</>
	)
}

export const ColTitle = ({ children }) => {
	return <h3 className={styles.colTitle}>{children}</h3>
}

export const Col = ({ children }) => {
	return <div className={styles.col}>{children}</div>
}

export default Footer
