import sms_aggregation from "assets/icons/sms_aggregation.svg"
import bulk_sms from "assets/icons/bulk_sms.svg"
import air_time from "assets/icons/air_time.svg"
import dialpad from "assets/icons/dialpad.svg"
import ussd from "assets/icons/ussd.svg"

export const content_solutions = {
	sectionTitle: {
		normal: "Our",
		bold: "Solutions",
	},
	list: [
		{
			icon: sms_aggregation,
			title: "SMS Aggregation",
			description: "Send and receive SMS easily from any network in South Sudan",
		},
		{
			icon: bulk_sms,
			title: "Bulk SMS",
			description: "Send to multiple people at the same time using one request.",
		},
		{
			icon: air_time,
			title: "Airtime",
			description: "Provide airtime distribution services to mobile phone users in South Sudan",
		},
		{
			icon: dialpad,
			title: "USSD",
			description: "Build interactive applications using our USSD API",
		},
		{
			icon: ussd,
			title: "Short Codes",
			description: "Offer short numbers to send and receive SMS in South Sudan.",
		},
		{
			icon: ussd,
			title: "AS Aggregation",
			description:
				"We provide a single point to consume value added services from mobile operators through our set of APIs with integration with client existing systems (e.g ERP) using channels such as SMS, USSD and Voice.",
		},
	],
}
