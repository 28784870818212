import { Container, Section, SectionHeading } from "components"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import * as styles from "./styles.module.scss"

const ContactUs = () => {
	return (
		<>
			<Section id="contact" className={styles.customSection}>
				<Container>
					<SectionHeading normal="Contact" bold="Us" />
					<div className={styles.wrapper}>
						<div>
							<form
								name="contact"
								method="post"
								data-netlify="true"
								data-netlify-honeypot="bot-field"
							>
								<input type="hidden" name="contact" value="contact" />
								<div className={styles.control}>
									<input type="text" name="full-name" placeholder="Full Name" />
								</div>
								<div className={styles.control}>
									<input type="email" name="email-address" placeholder="Email Address" />
								</div>
								<div className={styles.control}>
									<textarea
										name="message"
										cols="30"
										rows="10"
										placeholder="Your message"
									></textarea>
								</div>
								<input type="submit" value="SEND" className={styles.formSubmitBtn} />
							</form>
						</div>

						<div>
							<StaticImage src="../../../assets/images/contact.jpg" alt="" />
						</div>
					</div>
				</Container>
			</Section>
		</>
	)
}

export default ContactUs
