import React from "react"
import * as styles from "./styles.module.scss"
import { Container, Header, HeroHeading, Popup } from "components"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import slide_1 from "assets/images/slide_1.png"
import slide_2 from "assets/images/slide_2.png"
import slide_3 from "assets/images/slide_3.png"
import slide_4 from "assets/images/slide_4.png"

const slides = [slide_1, slide_2, slide_3, slide_4]

const Hero = () => {
	const settings = {
		arrows: false,
		autoplay: true,
		autoplaySpeed: 2500,
		infinite: true,
		speed: 500,
		draggable: false,
		slidesToShow: 1,
		slidesToScroll: 1,
	}

	return (
		<>
			<div className={styles.hero}>
				<Header />
				<Container>
					<div className={styles.heroInner}>
						<div>
							<HeroHeading>Enterprise Level Service for Bulk SMS</HeroHeading>
							<p className={styles.heroText}>
								JunubSMS is a bulk SMS provider in South Sudan having 5+ years experience in
								providing multi-routes message solutions to promote your business. Increase sales
								and customer satisfaction with smarter SMS campaigns, instant OTPs, notifications,
								two-way interactions, and other innovative bulk SMS services.
							</p>
							<Popup />
						</div>
						<div>
							<Slider {...settings}>
								{slides.map((item, index) => (
									<div key={index} className={styles.slideWrapper}>
										<img src={item} alt="" width={300} className={styles.slideImage} />
									</div>
								))}
							</Slider>
						</div>
					</div>
				</Container>
			</div>
		</>
	)
}

export default Hero
