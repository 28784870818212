import React from "react"
import * as styles from "./styles.module.scss"

const SectionHeading = ({ normal, bold, ...props }) => {
	return (
		<>
			<h2 className={styles.sectionHeading} {...props}>
				<span className={styles.normalText}>{normal}</span>{" "}
				<span className={styles.boldText}>{bold}</span>
			</h2>
		</>
	)
}

export default SectionHeading
