import React, { useState } from "react"
import axios from "axios"
import { Alert, Button, Form, Modal } from "react-bootstrap"
import Check from "assets/icons/Check"
import Cross from "assets/icons/Cross"
import { SIGNUP_API_ENDPOINT } from "constants"

const Popup = () => {
	const [show, setShow] = useState(false)
	const [isPending, setIsPending] = useState(false)
	const [validated, setValidated] = useState(false)
	const [formSuccess, setFormSuccess] = useState("")
	const [email, setEmail] = useState("")
	const [password, setPassword] = useState("")
	const [company, setCompany] = useState("")
	const [username, setUsername] = useState("")
	const [product, setProduct] = useState("")

	const handleClose = () => {
		setShow(false)
		setFormSuccess("")
		setValidated(false)
	}
	const handleShow = () => setShow(true)

	// Form submit
	const handleSubmit = (e) => {
		const form = e.currentTarget

		if (form.checkValidity() === false) {
			e.preventDefault()
			e.stopPropagation()
		}

		setValidated(true)

		if (form.checkValidity() === true) {
			e.preventDefault()
			setIsPending(true)
			const payload = {
				email: email,
				password: password,
				data: {
					company: company,
					username: username,
					product: product,
				},
			}
			axios
				.post(SIGNUP_API_ENDPOINT, payload)
				.then(() => {
					setIsPending(false)
					setFormSuccess("OK")
				})
				.catch((err) => {
					console.log("Error: ", err)
					setIsPending(false)
					setFormSuccess("FAIL")
				})
		}
	}

	return (
		<>
			<Modal show={show} onHide={handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>Register Now</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					<Form onSubmit={handleSubmit} className="mb-3" noValidate validated={validated}>
						<fieldset disabled={isPending || formSuccess === "OK"}>
							<Form.Group className="mb-3">
								<Form.Label visuallyHidden>Email address</Form.Label>
								<Form.Control
									name="email"
									type="email"
									placeholder="Enter your email"
									required
									onChange={(e) => setEmail(e.target.value)}
								/>
								<Form.Control.Feedback type="invalid">
									Email address is required
								</Form.Control.Feedback>
							</Form.Group>

							<Form.Group className="mb-3">
								<Form.Label visuallyHidden>Password</Form.Label>
								<Form.Control
									name="pass"
									type="password"
									minLength={5}
									placeholder="Enter password"
									required
									onChange={(e) => setPassword(e.target.value)}
								/>
								<Form.Control.Feedback type="invalid">
									Password is required (min. 5 characters)
								</Form.Control.Feedback>
							</Form.Group>

							<Form.Group className="mb-3">
								<Form.Label visuallyHidden>Company</Form.Label>
								<Form.Control
									name="company"
									type="text"
									placeholder="Company name"
									required
									onChange={(e) => setCompany(e.target.value)}
								/>
								<Form.Control.Feedback type="invalid">
									Company name is required
								</Form.Control.Feedback>
							</Form.Group>

							<Form.Group className="mb-3">
								<Form.Label visuallyHidden>Username</Form.Label>
								<Form.Control
									name="username"
									type="text"
									placeholder="Your username"
									required
									minLength={3}
									onChange={(e) => setUsername(e.target.value)}
								/>
								<Form.Control.Feedback type="invalid">
									Username is required (min. 3 characters)
								</Form.Control.Feedback>
							</Form.Group>

							<Form.Group className="mb-3">
								<Form.Label visuallyHidden>Select Product</Form.Label>
								<Form.Select
									defaultValue={""}
									name="product"
									onChange={(e) => setProduct(e.target.value)}
								>
									<option value="">Select a product</option>
									<option value="two-way-sms">Two Way SMS</option>
									<option value="bulk-sms">Bulk SMS</option>
									<option value="short-code">Short Code</option>
									<option value="air-time">Airtime</option>
								</Form.Select>
							</Form.Group>

							<Button variant="primary" className="text-white" type="submit">
								{isPending ? "Wait..." : "Signup"}
							</Button>
						</fieldset>
					</Form>

					{formSuccess === "OK" && (
						<Alert variant="success">
							<Check width={22} fill="green" className="me-2" />
							Verification email sent. Please check your inbox
						</Alert>
					)}

					{formSuccess === "FAIL" && (
						<Alert variant="danger">
							<Cross width={22} fill="tomato" className="me-2" />
							The was a problem sending your data to the server
						</Alert>
					)}
				</Modal.Body>
			</Modal>

			<Button variant="primary" className="text-white" onClick={handleShow}>
				Get Started
			</Button>
		</>
	)
}

export default Popup
