import client_geopoll from "assets/logos/client_geopoll.png"
import client_unicef from "assets/logos/client_unicef.png"
import client_marie_stopes from "assets/logos/client_marie_stopes.png"
import client_south_sodan from "assets/logos/client_south_sodan.png"
import client_buy_junub from "assets/logos/client_buy_junub.png"

export const content_clients = {
	sectionTitle: {
		normal: "Our",
		bold: "Clients",
	},
	logos: [
		{
			logo: client_geopoll,
			alt: "geopoll",
		},
		{
			logo: client_unicef,
			alt: "unicef",
		},
		{
			logo: client_marie_stopes,
			alt: "marie_stopes",
		},
		{
			logo: client_south_sodan,
			alt: "south_sodan",
		},
		{
			logo: client_buy_junub,
			alt: "buy_junub",
		},
	],
}
